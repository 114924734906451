
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        































































































































































































































.ab-sidebar-modification {
  &__types {
    margin-top: $--clb-layout-1;

    &-property {
      margin-bottom: $--clb-space-3;
    }

    &-reset {
      font-size: $--clb-font-size-xs;
      font-weight: 600;
      padding: $--clb-space-1 0;
      float: right;
      margin-top: 0 !important;
      margin-bottom: -20px !important;
    }
  }

  .app-code-mirror {
    min-height: 322px;

    .CodeMirror-scroll {
      max-height: 480px;
      min-height: 320px;
    }

    &.read-only {
      opacity: 0.5;

      &,
      .CodeMirror-scroll,
      .CodeMirror-lines {
        cursor: pointer;
      }
    }
  }
}
