
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        






































































































































































































































.ab-variant {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  &__loader,
  &__helper {
    background: $--clb-color-primary__white;
    z-index: 1;
    width: 100%;
  }

  &__helper {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;

    i {
      font-size: 72px;
      margin-top: 25vh;
      margin-bottom: $--clb-space-6;
    }
  }

  &__loader,
  &__frame {
    position: absolute;
    height: 100%;
    width: 100%;
    min-width: 768px;
    max-width: 9999px;
    overflow: auto;
    margin: 0;

    &.mobile {
      min-width: 375px;
      width: 375px;
      max-width: 375px;
      border: 1px solid $--clb-border-color-base;
      border-top: none;
      border-bottom: none;
      box-shadow: 0 0 80px -10px rgba(28, 24, 7, 0.1);
    }
  }
}
