
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        























































































































































































































.ab-sidebar-configuration {
  &__variant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $--clb-space-1;

    &-add i {
      padding-left: $--clb-space-1;
    }
  }
}
