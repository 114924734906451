
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        





























































































































































































































































































































































































































































































































.ab-integration {
  background: $--clb-color-primary__white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: calc(100 * var(--vh, 1vh) - #{$--clb-app-header-height});
  min-width: 420px;

  > * {
    display: flex;
  }

  &__header {
    padding: $--clb-space-2;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    background: $--clb-color-secondary__light;

    &-searchbar {
      height: 36px;
      flex-grow: 100;
      margin: 0 $--clb-space-2;

      .el-badge__content.is-fixed {
        top: 9px;
        right: $--clb-space-2;
        border-radius: $--clb-border-radius;
        transform: none;
        user-select: none;

        &.el-badge__content--success {
          background: #81cf74;
        }
      }

      &--url {
        border: 1px solid $--clb-border-color-base;
        border-radius: $--clb-border-radius;
        padding: $--clb-space-1 $--clb-space-2;
        font-size: $--clb-font-size-sm;
        outline: transparent;
        width: 100%;
        height: 100%;
      }
    }

    &-device {
      .el-button {
        &:focus {
          border-color: $--clb-border-color-base;
        }

        &:hover {
          border-color: $--jb-dark-primary-color;
        }
      }
    }

    .el-button:hover {
      transform: none;
      box-shadow: inherit;
    }
  }

  &__tabs {
    flex-direction: column;
    flex-grow: 1;

    > .el-tabs__header {
      margin-bottom: 0;
      background: $--clb-color-secondary__light;
      padding: 0 $--clb-space-2;

      .el-tabs__nav {
        border: none !important;
      }
    }

    .el-tabs__content,
    .el-tab-pane {
      display: flex;
      flex-grow: 1;
    }

    .el-tab-pane {
      display: flex;
      justify-content: center;
    }

    .el-tabs__item {
      padding: 0 $--clb-space-5 !important;
      margin: 0 $--clb-space-1 0 0;
      background: $--clb-skeleton-color;
      color: #bababa;
      border: 1px solid $--clb-border-color-base;
      border-bottom: none;
      border-radius: $--clb-border-radius $--clb-border-radius 0 0;

      &.is-active {
        background: #fff;
      }

      &:first-child {
        border-left: 1px solid $--clb-border-color-base !important;

        .el-icon-close {
          display: none;
        }
      }
    }
  }
}

.app-right-sidebar-extension .ab-integration__sidebar {
  h4 {
    margin: $--clb-layout-2 0 $--clb-space-2 0;
    font-size: 18px;
    color: $--clb-color__headings;
    font-weight: 700;
  }

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    line-height: $--clb-font-size-base;
    font-size: $--clb-font-size-sm;

    &--location {
      flex: 1 1 0;
      white-space: nowrap;

      i {
        display: inline-block;
        padding-right: $--clb-space-1;
        color: $--clb-border-color-dark;
      }

      &:hover {
        cursor: pointer;
        color: $--clb-body-font;

        i {
          color: $--clb-body-font;
        }
      }
    }

    &--size {
      flex: 0 1 0;
      cursor: pointer;
      min-width: 80px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      top: $--clb-mobile-padding;
      right: $--clb-mobile-padding;
      color: $--clb-border-color-dark;
      letter-spacing: 0.4px;
      transition: color $--clb-transition-time ease;

      span {
        color: transparent;
        display: inline-block;
        padding-right: $--clb-space-1;
        transform: translateX(10px);
        transition: $--clb-transition-time ease;
      }

      &:hover {
        color: $--clb-body-font;

        span {
          color: $--clb-body-font;
          transform: translateX(0);
        }
      }
    }
  }

  ul {
    li {
      padding: $--clb-space-4 0;
      border-bottom: 1px solid $--clb-border-color-base;
      display: flex;
      justify-content: space-between;
      font-size: $--clb-font-size-sm;

      i {
        color: $--clb-border-color-base;
        margin: 0 $--clb-space-2;
      }

      &:first-child {
        border-top: 1px solid $--clb-border-color-base;
      }

      &:hover {
        cursor: pointer;
        color: $--clb-color__headings;

        i {
          color: $--clb-color__headings;
        }
      }
    }
  }

  .app-button {
    width: 100%;
    margin: $--clb-layout-4 0 $--clb-space-2;
  }

  a {
    display: flex;
    justify-content: center;
    font-size: $--clb-font-size-xs;
    font-weight: 600;
    margin-top: $--clb-space-3;
  }

  .clear {
    display: inline-block;
    width: 100%;
    font-size: $--clb-font-size-xs;
    text-align: center;
  }

  .el-loading-spinner {
    i {
      font-size: 60px;
      margin-bottom: $--clb-layout-2;
    }

    .el-loading-text {
      max-width: 190px;
      margin: auto;
      font-weight: bold;
      color: $--clb-color__headings;
      font-size: $--clb-font-size-base;
    }
  }

  .el-form-item {
    margin-bottom: $--clb-space-4;
  }

  .el-input + .el-input,
  .el-input + .el-select {
    margin-top: $--clb-space-3;
  }

  .el-input--mini .el-textarea__inner,
  .el-input--mini .el-input__inner {
    padding: $--clb-space-1 $--clb-space-2 !important;
    font-size: $--clb-font-size-base !important;
  }

  .el-input-number,
  .el-input__inner {
    font-size: $--clb-font-size-base;
    min-height: 40px !important;
    width: 100%;
  }

  .el-input-group__append button.el-button {
    height: 40px;

    &:hover,
    &:active,
    &:focus {
      transform: none;
    }
  }

  .el-radio-group {
    display: flex;

    label {
      width: 100%;
    }

    .el-radio-button__inner {
      height: 40px;
      line-height: 40px;
      padding: 0 8px !important;
      width: 100%;
    }
  }
}
