
          @import "@/theme/jumbleberry/common/var.scss";
          @import "@/theme/clb/common/clb-var.scss";
          @import "@/theme/clb/utilities/clb-mixins.scss";
        



















































































.ab-sidebar-variant {
  .sortable-drag,
  .sortable-chosen,
  .el-icon-d-caret {
    cursor: grab !important;
  }

  .el-icon-d-caret {
    margin: 0 $--clb-space-1 !important;
  }

  &__modification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: $--clb-space-1;

    &-add i {
      padding-left: $--clb-space-1;
    }
  }

  &__modifications {
    user-select: none;

    .el-fade-in-move {
      transition: transform 0.6s;
    }

    li,
    span {
      display: flex;
      align-items: center;
      background: $--clb-color-primary__white;
      overflow: hidden;
    }

    &.empty li:hover {
      cursor: not-allowed !important;
    }
  }
}
